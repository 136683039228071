

<indoorviewer>
    <buttonGroup>
        <!--
        <button (click)="moveToDataset()">Dataset</button>
        <button (click)="moveToPoi()">Pois</button>
        <button (click)="addLocation()">Add location</button>
        <button (click)="startAnimation()">Start</button>
        -->
        <button (click)="cafeTour()" [hidden]="tourActive">Cafeteria</button>
        <button (click)="restaurantTour()" [hidden]="tourActive">Restaurant</button>
        <button (click)="fanTour()" [hidden]="tourActive">Fan Zone</button>
        <button (click)="makeRoute()" [hidden]="tourActive">Route</button>
    </buttonGroup>
</indoorviewer>