import { Component, OnInit } from '@angular/core';
import {getApi, ApiInterface, ViewOrientationInterface, ImageInterface} from "@navvis/indoorviewer";
import { async } from '@angular/core/testing';
import {GeoCoordinate} from 'src/app/GeoCoordinate';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'NavVisIndoorViewerTest';
  tourActive = false;

  public ivApi: ApiInterface;
  public animationPath: Location[] = [];

  private readonly fromCoordinate: GeoCoordinate =
    {
      lon:  8.55119,
      lat: 47.41158,
      alt:   3.67
    };

  private readonly toCoordinate: GeoCoordinate =
    {
      lon: 8.55146,
      lat: 47.41102,
      alt: 1.09
    };

  public ngOnInit(): void {
    
    getApi('https://idsoffice.mobile-3d-scanning.ch/iv')
      .then((iv: ApiInterface) => {
        this.ivApi = iv;
        // Code added here will execute after IndoorViewer is completely loaded.
        console.log('IndoorViewer successfully initialized...');
      }
    );

  }

  public addLocation(): void {
    const currentImage = this.ivApi.view.mainView.getImage() as ImageInterface;
    const currentFiledOfView = this.ivApi.view.mainView.getFov();
    const currentDirection = this.ivApi.view.mainView.currViewingDir;

    console.log(currentDirection);

    if (!currentImage) {
      alert('This is not a valid view. Please make sure that an image is visible.');
      return;
    }

    const newLocation: Location = {
      imageId: currentImage.id,
      filedOfView: currentFiledOfView,
      lat: currentDirection.lat,
      lon: currentDirection.lon
    };

    this.animationPath.push(newLocation);
    console.log('added location: ', currentImage.id, currentDirection);
  }

  public clearLocations(): void {
    this.animationPath = [];
  }

  public async startAnimation(): Promise<void> {
    console.log('start animation');
    for (const location of this.animationPath) {
      console.log('move to image: ', location.imageId, location.lat, location.lon);
      const viewDirection: ViewOrientationInterface = { lat: location.lat, lon: location.lon };
      await this.ivApi.legacyApi.moveToImageId(location.imageId, viewDirection, location.filedOfView);
    }
  }

  public async moveToDataset(): Promise<void> {

    const sites = await this.ivApi.siteModel.repository.findAll(true);
    console.log(sites.map(x => x.name));

    const images = await this.ivApi.image.repository.findAll(true);
    const orientation: ViewOrientationInterface = {
      lat: 0,
      lon: 0
    };

    for (const image of images) {
      await this.ivApi.legacyApi.moveToImage(image);
    }

    // const datasets = await 
    // const dataset = datasets[0];
    // this.ivApi.legacyApi.moveToDataset(dataset.id);
    //console.log(datasets);
  }

  public async moveToPoi(): Promise<void> {
    const pois = await this.ivApi.poi.repository.findAll();
    const firstPoi = pois[0];
    const secondPoi = pois[1];
    const thirdPoi = pois[2];
    console.log(pois);

    for (const poi of pois) {
      await this.ivApi.legacyApi.moveToPoi(poi, false, false, undefined);
      console.log(pois);
    }
  }

  public async cafeTour(): Promise<void> {
    const firstImage = await this.ivApi.image.repository.findOne(214);
    const secondImage = await this.ivApi.image.repository.findOne(362);
    const thirdImage = await this.ivApi.image.repository.findOne(426);
    const images = [firstImage, secondImage, thirdImage];
    return await this.Tour(images);
  }

  public async restaurantTour(): Promise<void> {
    const firstImage = await this.ivApi.image.repository.findOne(801);
    const secondImage = await this.ivApi.image.repository.findOne(772);
    const thirdImage = await this.ivApi.image.repository.findOne(817);
    const images = [firstImage, secondImage, thirdImage];
    return await this.Tour(images);
  }

  public async fanTour(): Promise<void> {
    const firstImage = await this.ivApi.image.repository.findOne(608);
    const secondImage = await this.ivApi.image.repository.findOne(633);
    const thirdImage = await this.ivApi.image.repository.findOne(645);
    const images = [firstImage, secondImage, thirdImage];
    return await this.Tour(images);
  }
  
  private async Tour(images): Promise<void>{
    this.tourActive = true;
    for (const image of images) {
      await this.ivApi.legacyApi.moveToImage(image);
      await delay(3000);
    }

    this.tourActive = false;
  }

  public makeRoute(): void
  {
    const Vector3 = this.ivApi.lib.THREE.Vector3;
    const from = new Vector3(
      this.fromCoordinate.lon,
      this.fromCoordinate.lat,
      this.fromCoordinate.alt);
    const to = new Vector3(
      this.toCoordinate.lon,
      this.toCoordinate.lat,
      this.toCoordinate.alt);

      this.ivApi.routing.route(from, to)
    .catch((reason) => alert(`Routing has failed, reason: ${reason}`));
  }
}


function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

export class Location {
  imageId: number;
  filedOfView: number;
  lon: number;
  lat: number;
}
